import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import ScrollLock from 'react-scrolllock';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from 'Components/modal';
import loc from 'Components/languages';
import Button from 'Components/button';
import { setAppLanguage } from 'Redux/actions_ui';
import styles from './ModalLanguage.less';

class ModalLanguage extends PureComponent {
	constructor(props) {
		super(props);
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '340px',
				padding: 0,
			},
		},
		this.onSelectLanguangeZhHantHandler = this.onSelectLanguangeHandler.bind(this, 'zh-Hant');
		this.onSelectLanguangeZhHansHandler = this.onSelectLanguangeHandler.bind(this, 'zh-Hans');
		this.onSelectLanguangeEnHandler = this.onSelectLanguangeHandler.bind(this, 'en');
		this.renderButton = this.renderButton.bind(this);
	}

	componentDidMount() {
		const { setAppLanguage } = this.props;
		setAppLanguage({ language: loc.Lang });
	}

	onSelectLanguangeHandler(lang, e) {
		const { setAppLanguage, onToggle } = this.props;
		e.preventDefault();
		loc.changeLang(lang);
		localStorage.setItem('lang', lang);
		setAppLanguage({ language: lang });
		onToggle();
	}

	renderButton(id, text, onClick) {
		const { appLanguage } = this.props;
		return (
			<div className={ styles.button }>
				{
					appLanguage && id === appLanguage.language && (
						<FontAwesomeIcon
							icon={ faCheckCircle }
							className={ classnames('uk-text-primary', styles.icon) }
						/>
					)
				}
				<Button
					theme="primary"
					text={ text }
					onClick={ onClick }
				/>
			</div>
		);
	}

	render() {
		const { isOpen, onToggle } = this.props;
		return (
			<Fragment>
				<ScrollLock isActive={ isOpen } />
				<Modal
					isOpen={ isOpen }
					onToggle={ onToggle }
					title={ loc.language }
					style={ this.modalStyle }
				>
					<div className={ styles.desc }>
						{ loc.selectLanguage }
					</div>

					<div className={ styles.buttons }>
						{ this.renderButton('zh-Hant', '繁體中文', this.onSelectLanguangeZhHantHandler) }
						{ this.renderButton('zh-Hans', '简体中文', this.onSelectLanguangeZhHansHandler) }
						{ this.renderButton('en', 'ENGLISH', this.onSelectLanguangeEnHandler) }
					</div>
				</Modal>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
	}),
	(dispatch) => ({
		setAppLanguage: para => dispatch(setAppLanguage(para)),
	})
)(ModalLanguage);