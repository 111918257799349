import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './FullMenuButton.less';

class FullMenuButton extends PureComponent {
	render() {
		const { icon, label, onClick, className } = this.props;
		return (
			<div
				className={ classnames('uk-card uk-card-default uk-card-body uk-card-small uk-card-hover', styles.button, className) }
				onClick={ onClick }
			>
				<div>
					<FontAwesomeIcon icon={ icon } className="fa-4x" />
					<br />
					<div className={ styles.label }>{ label }</div>
				</div>
			</div>
		);
	}
}

FullMenuButton.propTypes = {
	icon: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default FullMenuButton;