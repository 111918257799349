exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2NSBp {\n  background-color: #F6F4F0;\n  text-align: center;\n  line-height: 50px;\n  color: #a0a0a0;\n}\n.I7nxl {\n  width: 200px;\n  margin: 30px auto 0;\n}\n.I7nxl ._2Gflq {\n  position: relative;\n}\n.I7nxl ._2Gflq .Qmra8 {\n  position: absolute;\n  left: -70px;\n  top: 15px;\n  font-size: 25px;\n}\n.I7nxl ._2Gflq button {\n  width: 100%;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n", ""]);

// Exports
exports.locals = {
	"desc": "_2NSBp",
	"buttons": "I7nxl",
	"button": "_2Gflq",
	"icon": "Qmra8"
};